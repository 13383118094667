import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import { URLParse } from '../../utils/urlParse';
import {
  DEVICES,
  DEVICES_LIST,
  DEVICES_WIDTH,
  DEVICES_RAD,
} from '../../constants/devices';
import api from '../../services/api';
import DataCard from '../../components/DataCard/DataCard';


const propTypes = {
  url: PropTypes.string.isRequired,
};


class Screenshots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenshots: [],
      loading: true,
      empty: true,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    this.fetchData();
  }

  async fetchData() {
    const { url } = this.props;
    try {
      const { host, tld } = URLParse(url);
      const screenshots = DEVICES.map(
        device => ({
          device,
          image: api.report.getScreenshotURL(url, `${host}${tld}`, device),
        }),
      );
      this.setState({
        screenshots,
        loading: false,
        empty: false,
      });
    } catch (error) {
      this.setState({
        screenshots: null,
        loading: false,
        empty: true,
      });
    }
  }

  render() {
    const { screenshots, loading, empty } = this.state;
    return (
      <DataCard
        title="Mobile Screenshots"
        loading={loading}
        empty={empty}
      >
        <Grid
          spacing={32}
          container
          alignItems="center"
          justify="center"
        >
          {screenshots.map(s => (
            <Grid sm={6} md={6} item key={s.device}>
              <div style={{
                width: DEVICES_WIDTH[s.device],
                borderRadius: 25,
                border: '10px solid #000',
                margin: '0 auto',
              }}
              >
                <img
                  style={{
                    width: DEVICES_WIDTH[s.device],
                    borderRadius: DEVICES_RAD[s.device],
                  }}
                  src={s.image}
                  alt={DEVICES_LIST[s.device]}
                />
              </div>
              <Typography variant="overline" gutterBottom>
                {DEVICES_LIST[s.device]}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </DataCard>
    );
  }
}


Screenshots.propTypes = propTypes;
export default Screenshots;
