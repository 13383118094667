import React from 'react';
import PropTypes from 'prop-types';
import {
  TablePagination,
  TableRow,
  Hidden,
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import { withSnackbar } from 'notistack';
import moment from 'moment';

import DataTable from '../../components/DataTable/DataTable';
import TableCell from '../../components/DataTable/TableCell';
import SearchForm from '../../components/SearchForm/SearchForm';
import {
  STATUS_LIST,
  STATUS_LABEL,
  TYPE_LABEL,
  DONE,
  HIMSS,
} from '../../constants/queue';
import withAuth from '../../consumers/withAuth';
import api from '../../services/api';


const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

const propTypes = {
  paginate: PropTypes.bool,
  showEmptyRows: PropTypes.bool,
  search: PropTypes.bool,
  defaultLimit: PropTypes.number,
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  auth: PropTypes.oneOfType([PropTypes.object]).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

const defaultProps = {
  paginate: false,
  search: false,
  showEmptyRows: false,
  defaultLimit: 10,
};

class QueueTable extends React.Component {
  constructor(props) {
    super(props);
    const { defaultLimit } = this.props;
    this.state = {
      data: [],
      limit: defaultLimit,
      filters: {},
      total: 0,
      page: 0,
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleRedo = this.handleRedo.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
    this.renderRows = this.renderRows.bind(this);
  }

  componentWillMount() {
    this.fetchData();
  }

  static getTableHeader() {
    return [
      { id: 1, label: 'Client', hidden: { xsDown: true } },
      { id: 2, label: 'Date Created', hidden: { xsDown: true } },
      { id: 3, label: 'Email' },
      { id: 4, label: 'URL', hidden: { mdDown: true } },
      { id: 5, label: 'Status', hidden: { xsDown: true } },
      { id: 6, label: 'Type', hidden: { xsDown: true } },
      { id: 7, label: '' },
    ];
  }

  async fetchData(searchFilters = null, reset = false) {
    const { auth, history } = this.props;
    this.setState({ loading: true, data: [] });
    const { page, limit, filters } = this.state;
    const newFilters = searchFilters || filters;
    const offset = limit * page;
    const params = Object.assign({ offset, limit }, reset ? {} : newFilters);
    try {
      const response = await api.queue.get(params, auth.getToken());
      const { data } = response;
      this.setState({
        data: data.response,
        filters: reset ? {} : newFilters,
        total: data.count,
        loading: false,
      });
    } catch (error) {
      if (error.response.status === 401) {
        this.setState({
          data: [],
          filters: newFilters,
          loading: false,
        }, () => {
          auth.logout();
          history.replace('/logout');
        });
      }
      this.setState({
        data: [],
        filters: newFilters,
        loading: false,
      });
    }
  }

  handleChangePage(event, page) {
    this.setState({ page }, () => this.fetchData());
  }

  handleChangeRowsPerPage(event) {
    this.setState({ limit: event.target.value }, () => this.fetchData());
  }

  async handleRedo(url) {
    const { enqueueSnackbar, auth } = this.props;
    try {
      const item = {
        runType: 'unique',
        url,
      };
      const queue = await api.queue.getOne({ url }, auth.getToken());
      const { data } = queue;
      if (data && data.status !== DONE && data.status !== HIMSS) {
        enqueueSnackbar('An audit is already in progress for this site.', { variant: 'warning' });
      } else {
        await api.queue.process(item, auth.getToken());
        enqueueSnackbar('Successfully added site(s).', { variant: 'success' });
      }
    } catch (error) {
      const { response: { status } } = error;
      enqueueSnackbar('There was an error processing your request.', { variant: 'error' });
      if (status === 401) {
        auth.logout();
      }
    }
  }

  renderRows() {
    const { classes } = this.props;
    const { data } = this.state;
    if (!data.length) {
      return (
        <Grid
          spacing={32}
          container
          alignItems="center"
          justify="center"
        >
          <Grid sm={12} md={12} item>
            <Typography variant="overline" gutterBottom>
              No Data Available
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      data.map(row => (
        <TableRow
          hover
          tabIndex={-1}
          key={row.url}
        >
          <TableCell component="th" scope="row" padding="default">
            {row.client}
          </TableCell>
          <Hidden xsDown>
            <TableCell>
              { moment(row.creationDate).format('MMM DD, YYYY') }
            </TableCell>
          </Hidden>
          <TableCell>
            {(row.email.length > 1) ? `${row.email[0]} ... +${row.email.length - 1}` : row.email[0]}
          </TableCell>
          <Hidden mdDown>
            <TableCell>
              {row.url}
            </TableCell>
          </Hidden>
          <Hidden xsDown>
            <TableCell>
              <Typography variant="button" color="textSecondary" gutterBottom>
                {STATUS_LABEL[row.status]}
              </Typography>
            </TableCell>
          </Hidden>
          <Hidden xsDown>
            <TableCell>
              <Typography variant="button" color="textSecondary" gutterBottom>
                {TYPE_LABEL[row.type]}
              </Typography>
            </TableCell>
          </Hidden>
          <TableCell padding="none" isActions>
            {(row.status === DONE || row.status === HIMSS) && (
              <Button
                variant="text"
                component="span"
                color="secondary"
                className={classes.button}
                onClick={() => { this.handleRedo(row.url); }}
              >
                <RefreshIcon className={classes.leftIcon} />
                Redo
              </Button>
            )}
          </TableCell>
        </TableRow>
      ))
    );
  }

  renderPagination() {
    const { total, limit, page } = this.state;
    const { defaultLimit } = this.props;
    let rowsPerPageOptions = [5, 10, 25];
    if (defaultLimit > 10) {
      rowsPerPageOptions = [25, 50, 75];
    }
    return (
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={limit}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
  }

  renderSearch() {
    const fields = [
      {
        id: 'url',
        name: 'url',
        label: 'Website URL',
        type: 'text',
        validateAs: 'string',
        defaultVal: '',
        required: false,
      },
      {
        id: 'status',
        name: 'status',
        label: 'Status',
        type: 'select',
        placeholder: 'Select one',
        validateAs: 'bool',
        defaultVal: '',
        required: false,
        customProps: {
          inputProps: {
            name: 'status',
            id: 'status',
          },
          data: STATUS_LIST,
        },
      },
      {
        id: 'dateGreater',
        name: 'dateGreater',
        label: 'Date From',
        type: 'date',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        customProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },
      {
        id: 'dateLess',
        name: 'dateLess',
        label: 'Date To',
        type: 'date',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        customProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },
    ];

    return (
      <SearchForm
        onSubmit={this.fetchData}
        onReset={() => this.fetchData(null, true)}
        fields={fields}
      />);
  }

  render() {
    const {
      data,
      limit,
      offset,
      loading,
    } = this.state;
    const { paginate, search, showEmptyRows } = this.props;
    let emptyRows = 0;
    if (showEmptyRows) {
      emptyRows = limit - Math.min(limit, data.length - offset * limit);
    }
    const searchComponent = (search) ? this.renderSearch() : null;
    return (
      <div>
        {searchComponent}
        <DataTable
          header={QueueTable.getTableHeader()}
          renderRows={this.renderRows}
          emptyRows={emptyRows}
          paginate={paginate}
          renderPagination={this.renderPagination}
          loading={loading}
          empty={data.length === 0}
        />
      </div>
    );
  }
}


QueueTable.propTypes = propTypes;
QueueTable.defaultProps = defaultProps;

const QueueTableWithSnack = withSnackbar(QueueTable);
const QueueTableWithAuth = withAuth(QueueTableWithSnack);
export default withStyles(styles, { withTheme: true })(QueueTableWithAuth);
