import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { AppContainer } from 'react-hot-loader';
import { SnackbarProvider } from 'notistack';

import AuthProvider from './providers/auth';
import AuthConsumer from './consumers/auth';
import App from './containers/App/App';
// import registerServiceWorker from './registerServiceWorker';
import defaultTheme from './styles/theme';


const theme = createMuiTheme(defaultTheme);

const render = (Component) => {
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <AppContainer>
          <AuthProvider>
            <AuthConsumer>
              <Component />
            </AuthConsumer>
          </AuthProvider>
        </AppContainer>
      </SnackbarProvider>
    </MuiThemeProvider>,
    document.getElementById('root'),
  );
};

// registerServiceWorker();

render(App);

if (module.hot && process.env.REACT_APP_STAGE === 'dev') {
  module.hot.accept('./containers/App/App', () => {
    render(App);
  });
}
