import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import QueueTable from '../containers/QueueTable/QueueTable';

const styles = theme => ({
  list: {
    marginTop: theme.spacing.unit,
  },
});

const propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const DashboardPage = ({ history, classes }) => (
  <div className={classes.list}>
    <QueueTable history={history} paginate search />
  </div>
);


DashboardPage.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(DashboardPage);
