import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  Typography,
  Button,
  Fab,
  Dialog,
  Slide,
  IconButton,
  AppBar,
  Toolbar,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';

import withAuth from '../../consumers/withAuth';
import api from '../../services/api';


const styles = theme => ({
  template: {
    marginTop: 100,
  },
  flex: {
    flex: 1,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
    zIndex: 999,
  },
  container: {
    minHeight: '100vh',
  },
  icon: {
    padding: 20,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class PreviewEmail extends React.Component {
  static showLoader(classes, message = 'Loading Template') {
    return (
      <Grid justify="center" alignItems="center" direction="column" className={classes.container} container>
        <CircularProgress color="secondary" className={classes.icon} />
        <Typography variant="h5" align="center" gutterBottom>
          {message}
        </Typography>
      </Grid>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      template: null,
      submitting: false,
    };
    this.fetchData = this.fetchData.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async fetchData() {
    const { auth, url } = this.props;
    try {
      const response = await api.report.getTemplate(url, auth.getToken());
      this.setState({
        template: response.data,
      });
    } catch (error) {
      const { response: { status } } = error;
      this.setState({ template: null });
      if (status === 401) {
        auth.logout();
      }
    }
  }

  async handleOpen() {
    this.setState({ open: true });
    await this.fetchData();
  }

  handleClose() {
    this.setState({ open: false });
  }

  async handleSubmit() {
    const { auth, url, enqueueSnackbar } = this.props;
    try {
      this.setState({ submitting: true });
      await api.report.sendEmail(url, auth.getToken());
      this.setState({ submitting: false });
      enqueueSnackbar('Successfully Submitted.', { variant: 'success' });
    } catch (error) {
      this.setState({ submitting: false });
      const { response: { status } } = error;
      enqueueSnackbar('There was an error processing your request.', { variant: 'error' });
      if (status === 401) {
        auth.logout();
      }
    }
    this.handleClose();
  }

  render() {
    const { classes } = this.props;
    const { template, open, submitting } = this.state;
    let content = null;
    if (template === null && open) {
      content = PreviewEmail.showLoader(classes);
    } else if (submitting) {
      content = PreviewEmail.showLoader(classes, 'Submitting Audit');
    } else {
      content = (
        <div
          className={classes.template}
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{ __html: template }}
        />);
    }
    return (
      <div>
        <Fab color="secondary" aria-label="Preview" className={classes.fab} onClick={this.handleOpen}>
          <SendIcon />
        </Fab>
        <Dialog
          fullScreen
          open={open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar>
            <Toolbar>
              <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Preview
              </Typography>
              <Button color="inherit" onClick={this.handleSubmit}>
                Submit
              </Button>
            </Toolbar>
          </AppBar>
          {content}
        </Dialog>
      </div>
    );
  }
}


PreviewEmail.propTypes = propTypes;
const PreviewWithSnack = withSnackbar(PreviewEmail);
export default withStyles(styles, { withTheme: true })(withAuth(PreviewWithSnack));
