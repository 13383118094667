let API_URL = 'http://localhost:3000';
let WWW_HOST = 'http://localhost:3001';
let AUTH0_AUDIENCE = 'https://mweaudit.mwe.cloud/';

if (process.env.REACT_APP_STAGE === 'test') {
  API_URL = 'https://mweaudit.mwe.cloud';
  WWW_HOST = 'https://mweauditwww.mwe.cloud';
  AUTH0_AUDIENCE = 'https://mweaudit.mwe.cloud/';
} else if (process.env.REACT_APP_STAGE === 'prod') {
  API_URL = 'https://mweaudit.mwe.cloud';
  WWW_HOST = 'https://mweauditwww.mwe.cloud';
  AUTH0_AUDIENCE = 'https://mweaudit.mwe.cloud/';
}

export default {
  api: {
    baseURL: API_URL,
    timeout: 20000,
  },
  auth0: {
    domain: 'mweaudit.auth0.com',
    audience: AUTH0_AUDIENCE,
    clientID: 'waWoAcobblW1nLsqMSBHNzoI5Ra7TPw1',
    redirectUri: `${WWW_HOST}/callback`,
    responseType: 'token id_token',
    scope: 'openid profile',
  },
};
