import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  Paper,
  Grid,
  Button,
  Stepper as MuiStepper,
  Step,
  StepLabel,
} from '@material-ui/core';

import { BASIC, PRO, TODO } from '../../constants/queue';
import api from '../../services/api';
import withAuth from '../../consumers/withAuth';
import AddStep from './AddStep';
import SelectTypeStep from './SelectTypeStep';
import DoneStep from './DoneStep';


const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit,
  },
  paperBottom: {
    padding: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
  },
  stepContainer: {
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonsContainer: {
    marginTop: 50,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  auth: PropTypes.oneOfType([PropTypes.object]).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};


class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      data: [],
    };
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleFinish = this.handleFinish.bind(this);
    this.handleAddData = this.handleAddData.bind(this);
    this.handleSelectedType = this.handleSelectedType.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleKeywords = this.handleKeywords.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getStepContent = this.getStepContent.bind(this);
  }


  getStepContent(stepIndex) {
    const { data } = this.state;
    switch (stepIndex) {
      case 0:
        return (
          <AddStep
            onAddData={this.handleAddData}
            onError={this.handleError}
          />);
      case 1:
        return (
          <SelectTypeStep
            data={data}
            onTypeSelected={this.handleSelectedType}
            onDelete={this.handleDelete}
            onKeywordsChange={this.handleKeywords}
          />);
      case 2:
        return (<DoneStep data={data} onSubmit={this.handlePriority} />);
      default:
        return 'Uknown stepIndex';
    }
  }

  handleNext() {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  }

  handleBack() {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  }

  handleFinish() {
    this.setState({
      data: [],
      activeStep: 0,
    });
  }

  handleKeywords(url, keywords) {
    const { data } = this.state;
    const idx = data.findIndex(row => (row.url === url));
    data[idx].keywords = keywords;
    this.setState({ data });
  }

  handleAddData(items) {
    const { data } = this.state;
    const { enqueueSnackbar, auth } = this.props;
    const owner = auth.getUser();
    if (data.length > 100) {
      enqueueSnackbar('The content of the file is too large. Max 100 rows.', { variant: 'success' });
      this.setState({ data: [] });
    }
    items.forEach((row) => {
      const exists = data.find(site => site.url === row.url);
      if (!exists) {
        let keywords = [];
        if (row.type === PRO) {
          keywords = row.keywords.split('|');
        }
        const email = row.email.split('|');
        data.push({
          ...row,
          email,
          type: row.type ? row.type.trim() : BASIC,
          keywords,
          status: TODO,
          owner: `${owner.nickname}@medicalwebexperts.com`,
        });
      }
    });
    enqueueSnackbar('Successfuly added client(s)', { variant: 'success' });
    this.setState({ data }, () => this.handleNext());
  }

  handleError(err) {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(`There was an error adding clients, please check the CSV file or the data entered. ${err}`, { variant: 'error' });
  }

  handleSelectedType(element) {
    const { data } = this.state;
    const idx = data.findIndex(row => (row.url === element.url));
    if (element.type === BASIC) {
      data[idx].type = PRO;
    } else {
      data[idx].type = BASIC;
      data[idx].keywords = [];
    }
    this.setState({ data });
  }

  handleDelete(element) {
    const { data } = this.state;
    const { enqueueSnackbar } = this.props;
    const idx = data.findIndex(row => (row.url === element.url));
    data.splice(idx, 1);
    enqueueSnackbar('Successfuly removed client.', { variant: 'success' });
    this.setState({ data });
  }

  async handleSubmit() {
    const { data } = this.state;
    const { enqueueSnackbar, auth } = this.props;
    try {
      await api.queue.create(data, auth.getToken());
      if (data.length === 1) {
        const item = {
          runType: 'unique',
          url: data[0].url,
        };
        await api.queue.process(item, auth.getToken());
      }
      enqueueSnackbar('Successfully added site(s).', { variant: 'success' });
      this.handleNext();
    } catch (error) {
      const { response: { status } } = error;
      enqueueSnackbar('There was an error processing your request.', { variant: 'error' });
      if (status === 401) {
        auth.logout();
      }
    }
  }

  render() {
    const { classes } = this.props;
    const steps = ['Add Sites', 'Select An Audit Type', 'Done'];
    const { activeStep, data } = this.state;
    const isFirst = activeStep === 0;
    const isLast = activeStep === steps.length - 1;
    const isSubmit = activeStep === 1;
    let action = this.handleNext;
    if (isSubmit) {
      action = this.handleSubmit;
    }
    if (isLast) {
      action = this.handleFinish;
    }

    return (
      <Paper className={classes.paper}>
        <MuiStepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>
                {label}
              </StepLabel>
            </Step>
          ))}
        </MuiStepper>
        <div>
          <div className={classes.stepContainer}>
            <Grid
              spacing={32}
              container
              alignItems="center"
              justify="center"
            >
              {this.getStepContent(activeStep)}
            </Grid>
          </div>
          <div className={classes.buttonsContainer}>
            <Grid
              alignItems="flex-end"
              justify="flex-end"
              xs={12}
              container
              item
            >
              <Button
                disabled={isFirst || isLast}
                onClick={this.handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button variant="contained" disabled={data.length === 0} color="primary" onClick={action}>
                {isLast ? 'Finish' : 'Next'}
              </Button>
            </Grid>
          </div>
        </div>
      </Paper>
    );
  }
}


Stepper.propTypes = propTypes;
const StepperWithSnack = withSnackbar(Stepper);
const StepperWithAuth = withAuth(StepperWithSnack);
export default withStyles(styles, { withTheme: true })(StepperWithAuth);
