export const TODO = 'todo';
export const PROGRESS = 'progress';
export const DONE = 'done';
export const PENDING = 'pending';
export const HIMSS = 'himss';

export const STATUS_LABEL = {
  [TODO]: 'To-Do',
  [PROGRESS]: 'In Progress',
  [DONE]: 'Done',
  [PENDING]: 'Pending',
  [HIMSS]: 'HIMSS - iPad',
};

export const STATUS_LIST = Object.keys(STATUS_LABEL)
  .map(value => ({ label: STATUS_LABEL[value], value }));

export const BASIC = 'basic';
export const PRO = 'pro';

export const TYPE_LABEL = {
  [BASIC]: 'Basic',
  [PRO]: 'Pro',
};

export const TYPE_LIST = Object.keys(TYPE_LABEL)
  .map(value => ({ label: TYPE_LABEL[value], value }));
